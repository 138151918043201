<template>
   <div class="container">
      <div class="row justify-content-md-center">
         <div class="col-12 mt-3 mb-5">

             <div class="account-slice">
               <h4 class="text-center mb-4">Account Support</h4>
               <p class="text-secondary">Use this page to extend credit usage re-tries and start or terminate free subscriptions.</p>

               <h5>Account Details:</h5>
               <p>Username: {{accountData.username}}</p>
               <p>Account#: {{accountData.account_number}}</p>

               <hr/>

               <h4 class="text-center mb-4">Create Subscription</h4>
               <label>Slice count for new subscription: </label>
               <select v-model="createSubscriptionMaxSliceCount">
                  <option value="select">(select)</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="7">7</option>
                  <option value="11">11</option>
               </select>-slices, expiring on (optional): 
               <input v-model="createSubscriptionExpiration" size=7 placeholder="expiration" />

               <div>
                  <input placeholder="confirm" size=7 v-model="confirmTexts['createSub']" />
                  <button @click="createSub()" v-if="confirmTexts['createSub'] === 'confirm'">
                     Create Now
                  </button>
                  <button v-else style="opacity: 0.5">
                     Create Now
                  </button>
               </div>

               <hr/>

               <h4 class="text-center mb-4">Existing Subscriptions</h4>
               <table style="border-spacing: 10px; border-collapse: separate !important;">
               <thead>
                  <tr>
                     <th>Slice #</th>
                     <th>Created</th>
                     <th>Method</th>
                     <th>Canceled</th>
                     <th>Expiration</th>
                     <th>Terminate</th>
                  </tr>
               </thead>
               <tbody>
                  <tr style="border-top: solid; width:100%" v-for="sub in accountData.subscriptions" :key="sub.id">
                     <td class="credit-type">
                        {{sub.max_slice_count}}-Slice
                     </td>
                     <td class="pr-3 text-center">
                        {{(new Date(sub.created)).toLocaleDateString()}}
                        <span class="time">{{(new Date(sub.created)).toLocaleTimeString()}}</span>
                     </td>
                     <td>
                        {{sub.is_admin ? "Free" : "Purchased"}}
                     </td>
                     <td>
                        {{sub.is_canceled ? "CANCELED" : ""}}
                     </td>
                     <td>
                        {{sub.expiration ? (new Date(sub.expiration)).toLocaleDateString() : ""}}
                     </td>
                     <td>
                        <div v-if="!sub.is_canceled">
                           <input placeholder="confirm" size=7 v-model="confirmTexts[sub.id]" />
                           <button @click="cancelSub(sub.id)" v-if="confirmTexts[sub.id] === 'confirm'">
                              Cancel Now
                           </button>
                           <button v-else style="opacity: 0.5">
                              Cancel Now
                           </button>
                        </div>
                     </td>
                  </tr>
               </tbody>
               </table>

               <hr/>

               <h4 class="text-center mb-4">Reactivate Credits</h4>
               <table style="border-spacing: 10px; border-collapse: separate !important;">
               <thead>
                  <tr>
                     <th>Slice #</th>
                     <th>Initial</th>
                     <th>Latest</th>
                     <th>Validated</th>
                     <th>Cust. Uses</th>
                     <th>Opportunities</th>
                     <th>Status</th>
                     <th>Administer</th>
                  </tr>
               </thead>
               <tbody>
                  <tr style="border-top: solid; width:100%" v-for="creditUsed in accountData.credits_used" :key="creditUsed.id">
                     <td class="credit-type">
                        {{creditUsed.max_slice_count}}-Slice
                     </td>
                     <td class="pr-3 text-center">
                        {{(new Date(creditUsed.created)).toLocaleDateString()}}
                     </td>
                     <td class="pr-3 text-center">
                        {{(new Date(creditUsed.activated_date)).toLocaleDateString()}}
                        <span class="time">{{(new Date(creditUsed.activated_date)).toLocaleTimeString()}}</span>
                     </td>
                     <td>
                        {{creditUsed.is_finalized}}
                     </td>
                     <td>
                        {{creditUsed.retries_so_far}}
                     </td>
                     <td>
                        {{creditUsed.retries_available}}
                     </td>
                     <td :style="(creditUsed.retries_available > creditUsed.retries_so_far ? 'color: green' : 'color: red')">
                        {{creditUsed.retries_available > creditUsed.retries_so_far ? "available" : "used"}}
                     </td>
                     <td>
                        <div v-if="creditUsed.retries_so_far >= creditUsed.retries_available">
                           <input placeholder="confirm" size=7 v-model="confirmTexts[creditUsed.id]" />
                           <button @click="extendCredit(creditUsed.id)" v-if="confirmTexts[creditUsed.id] === 'confirm'">
                              Make Available
                           </button>
                           <button v-else style="opacity: 0.5">
                              Make Available
                           </button>
                        </div>
                     </td>
                  </tr>
               </tbody>
               </table>
            </div>
         </div>
      </div>

      <div class="row justify-content-md-center">
         <div>
            For canned responses:<br/><a target=_blank href="https://docs.google.com/document/d/1-nQc-2r--O-Bq5kfkJ_m7cMbKCcSaqxa99G_d5NxC9Q/edit?usp=sharing">https://docs.google.com/document/d/1-nQc-2r--O-Bq5kfkJ_m7cMbKCcSaqxa99G_d5NxC9Q/edit?usp=sharing</a>
            </div>
         <div>

         <div class="col-12 mt-3 mb-5">
            <p>Message to post on account's messages page:</p>
            <textarea style="width: 600px" v-model="messageToPost" />
         </div>
            <input placeholder="confirm" size=7 v-model="confirmTextSendMessage" />
            <button @click="sendMessage()" v-if="confirmTextSendMessage === 'confirm'">
               Send Message
            </button>
            <button v-else style="opacity: 0.5">
               Send Message
            </button>
         </div>
      </div>


      <div class="container">
         <table class="mt-4">
         <tbody>
         <tr  
            class="row message-row" 
            v-for="message in messages"
            :key="message">

            <td class="p-3">{{ (new Date(message.created)).toLocaleDateString('en-US') }} </td>
            <td class="p-3">{{ (new Date(message.created)).toLocaleTimeString('en-US') }} </td>
            <td class="p-3">{{ message.message }}</td>
            <hr/>
            
         </tr>
         </tbody>
         </table>
      </div>


   </div>
</template>

<script>
export default {
   name: "AccountAdmin",
   data() {
      return {
         confirmTextSendMessage: "",
         confirmTexts: {},
         accountData: {},
         accountNumber: "",
         messageToPost: "",
         messages: [],
         createSubscriptionMaxSliceCount: "select",
         createSubscriptionExpiration: ""
      }
   },
   mounted() {
      this.accountNumber = this.$route.query.account;
      this.axios
         .post("/api/support-admin-get-data", {"accountNumber": this.accountNumber}, { withCredentials: true })
         .then((response) => {
            console.log(response.data.account_data);
            this.accountData = response.data.account_data;
            this.messages = response.data.support_messages;
         })
         .catch((error) => console.error(error));
   },
   methods: {
      createSub() {
         this.axios
            .post("/api/support-admin-create-subscription", 
               {
                  "accountNumber": this.accountNumber,
                  "maxSliceCount": parseInt(this.createSubscriptionMaxSliceCount),
                  "expiration": (this.createSubscriptionExpiration ? Date.parse(this.createSubscriptionExpiration) : null),
               },
               { withCredentials: true })
            .then(() => {
               window.location.assign(window.location.href);
            })
            .catch((error) => console.error(error));
      },
      cancelSub(subId) {
         this.axios
            .post("/api/support-admin-cancel-subscription", 
               {
                  "accountNumber": this.accountNumber,
                  "subId": subId
               },
               { withCredentials: true })
            .then(() => {
               window.location.assign(window.location.href);
            })
            .catch((error) => console.error(error));
      },
      extendCredit(creditId) {
         this.axios
            .post("/api/support-admin-extend-credit", 
               {
                  "accountNumber": this.accountNumber,
                  "creditId": creditId
               },
               { withCredentials: true })
            .then(() => {
               window.location.assign(window.location.href);
            })
            .catch((error) => console.error(error));
      },
      sendMessage() {
         this.axios
            .post("/api/support-admin-send-message", 
               {
                  "accountNumber": this.accountNumber,
                  "message": this.messageToPost
               },
               { withCredentials: true })
            .then(() => {
               window.location.assign(window.location.href);
            })
            .catch((error) => console.error(error));
      }
   }
}
</script>
